<template>
  <div>
    <titleBar :image=this.image :title-cn="this.titleCn" :title-en=this.titleEn />
    <titleFont title="外贸第三方服务"></titleFont>
    <div id="content">
      <div id="content-title">
        提供知识产权代理、海外公司注册、海外商标注册、转口贸易协同、企业财税服务、外贸人员服务外<br/>
        包、软件开发、产品设计、研发、专业咨询等第三方优质服务商连接、推荐。
      </div>
      <div id="content-imgs">
        <img src="@/assets/partyService/1.jpg"/>
        <img src="@/assets/partyService/2.jpg"/>
        <img src="@/assets/partyService/3.jpg"/>
      </div>
    </div>
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";
import titleFont from "@/components/common/titleFont.vue";

export default {
  components: {
    titleBar,
    footLink,
    titleFont
  },
  data() {
    return {
      titleCn: '外贸第三方服务',
      titleEn: 'FOREIGN TRADETHIRD-PARTY SERVICE',
      image: require('@/assets/partyService/4.png'),
    }
  },
}
</script>

<style lang="less" scoped>
#content {
  margin-top: 50px;
  margin-bottom: 50px;
}
.content {
  margin-top: 50px;
}
#content-title {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 2;           /* 行高 */
  text-align: center;           /* 对齐方式 */
  letter-spacing: 0.5px;      /* 字母间距 */
  word-spacing: 5px;          /* 单词间距 */
}
#content-imgs {
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  margin-right: 30px;
  width: 25%;
}
</style>


