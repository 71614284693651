<template>
  <div>
    <titleBar :image=this.image :title-cn="this.titleCn" :title-en=this.titleEn />
    <div id="allContent">
      <div class="content">
        <img src="@/assets/customs/2.jpg">
        <div class="content-font">
          <p id="title-customs">服务介绍</p>
          <p class="content1">针对一般贸易出口客户，提供全国各口岸的通关服务:</p>
          <ul>
            <li class="content2">出口单据制作，包括报关报检单据、产地证、保险单等各类单证。</li>
            <li class="content2">根据信用证条款，审单、制单、交单，规避风险。</li>
            <li class="content2">专业的商编归类服务。</li>
          </ul>
        </div>
      </div>
      <div class="content">
        <img src="@/assets/customs/3.jpg">
        <div class="content-font">
          <ul>
            <li class="content1">全口岸电子报关，及时高效反馈通关信息。</li>
            <li class="content1">专业的服务团队，丰富的通关经验，为客户解决通关难题，享有安全、可靠、专业的报关服务。</li>
          </ul>
        </div>
      </div>
    </div>
    <process :arrows=this.arrows :titleContext=this.titleContext />
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";
import process from "@/components/common/process.vue";

export default {
  components: {
    process,
    titleBar,
    footLink
  },
  data() {
    return {
      titleCn: '通关服务',
      titleEn: 'BE CLEARED BY CUSTOMS',
      image: require('@/assets/customs/1.jpg'),
      titleContext : '通关服务',
      arrows: [
        {
          serial:'01',
          describe:'注册'
        },
        {
          serial:'02',
          describe:'下单'
        },
        {
          serial:'03',
          describe:'报关'
        },
        {
          serial:'04',
          describe:'收付款'
        },
        {
          serial:'05',
          describe:'出口退税'
        },
        {
          serial:'06',
          describe:'结算'
        }
      ]
    }
  },
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#allContent {
  margin-top: 30px;
  margin-bottom: 30px;
}
img {
  height: 300px;
  margin-bottom: 30px;
  margin-right: 30px;
}
.content-font {
  width: 30%;
}
#title-customs {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 900;
  background-image: linear-gradient(to right, rgb(68, 221, 208), rgb(9, 182, 245));
  -webkit-background-clip: text; /* 使背景渐变应用于文本 */
  -webkit-text-fill-color: transparent; /* 使文本颜色透明，从而显示背景渐变 */
  display: flex;
  align-items: center; /* 垂直居中对齐 */
}
.content1 {
  font-weight: bold;
  color: dimgrey;
  line-height:2;
}
.content2 {
  font-weight: bold;
  color: darkgrey;
  line-height:2;
}
</style>


