import { render, staticRenderFns } from "./homeFoot.vue?vue&type=template&id=bd7e489e&scoped=true"
import script from "./homeFoot.vue?vue&type=script&lang=js"
export * from "./homeFoot.vue?vue&type=script&lang=js"
import style0 from "./homeFoot.vue?vue&type=style&index=0&id=bd7e489e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd7e489e",
  null
  
)

export default component.exports