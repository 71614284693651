<template>
  <div>
    <div class="homeFoot-top">
      <div id="blank"></div>
      <div id="cooperative-title">
        合作流程
      </div>
      <div id="cooperative-con">
        良好的服务始终是我们的追求!我们在市场的每一步都离不开您的支持!
      </div>
      <div class="line"></div>
      <div class="arrows">
        <div v-for="(item, index) in arrows" :key="index" >
          <arrowHome :serial="item.serial" :describe="item.describe"/>
        </div>
      </div>
    </div>
    <div id="upperStrata">
      <div>
        <div id="oneStop">一站式外贸综合服务平台</div>
        <div id="oneStop-con">提供通关、物流、结算、退税、金融等解决方案</div>
      </div>
      <div id="middle"></div>
      <div id="consulting">
        <el-link id="contactUs" :underline="false" @click="navigateTo('/contactUs')">在线咨询</el-link>
      </div>
    </div>
    <div class="homeFoot-bottom">
      <div id="cooperative">生态合作伙伴</div>
      <div class="cooperativeImg" >
        <img class="cooperativeItem" src="@/assets/cooperative/图片4.png">
<!--        <img class="cooperativeItem" src="@/assets/cooperative/中国银行.png">-->
<!--        <img class="cooperativeItem" src="@/assets/cooperative/交通银行.png">-->
<!--        <img class="cooperativeItem" src="@/assets/cooperative/招商银行.png">-->
<!--        <img class="cooperativeItem" src="@/assets/cooperative/平安银行.png">-->
      </div>
<!--      <div class="cooperativeImg">-->
<!--        <img class="cooperativeItem" src="@/assets/cooperative/富友支付.png">-->
<!--        <img class="cooperativeItem" src="@/assets/cooperative/连连.png">-->
<!--        <img class="cooperativeItem" src="@/assets/cooperative/陕西电子口岸.png">-->
<!--        <img class="cooperativeItem" src="@/assets/cooperative/中国信保.png">-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import arrowHome from '../../components/home/arrowHome';
export default {
  components: {
    arrowHome
  },
  data() {
    return {
      arrows: [
        {
          serial:'01',
          describe:'报名申请'
        },
        {
          serial:'02',
          describe:'确认合作'
        },
        {
          serial:'03',
          describe:'补充信息'
        },
        {
          serial:'04',
          describe:'服务选择'
        },
        {
          serial:'05',
          describe:'下单出口'
        },
        {
          serial:'06',
          describe:'收结汇&退税'
        },
        {
          serial:'07',
          describe:'结算'
        }
      ]
    }
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="less" scoped>
.homeFoot-top {
  background-image: url("/src/assets/homepageimges/4.jpg");
  width: 100%;
  height: 500px;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 水平居中文本 */
  position: relative;
  z-index: 1;
}
.homeFoot-bottom {
  display: flex; /* 使用Flexbox布局 */
  flex-direction: column; /* 设置主轴为垂直方向 */
  width: 100%;
  background-image: url("/src/assets/homepageimges/5.jpg");
  height: 450px;
  justify-content: center; /* 水平居中 */
  text-align: center; /* 水平居中文本 */
  position: relative;
  z-index: 1;
}
#upperStrata{
  top: 3475px;
  display: flex;
  position: absolute;
  width: 80%;
  height: 120px;
  border: 2px solid whitesmoke; /* 边框颜色为红色 */
  border-radius: 20px; /* 弧度值 */
  margin-left: 10%;
  z-index: 2;
  background: white;
}
#cooperative {
  margin-top: 70px;
  font-size: 30px;
  font-weight: bold;
  color: white;
}
.arrows {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#cooperative-title {
  color: white;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
}
#blank{
  height: 40px;
}
.line {
  width: 6%;
  height: 3px;
  margin: auto;
  margin-top: 10px;
  background-color: white;
}
#cooperative-con {
  color: white;
}
#oneStop {
  width: 100%;
  padding-left: 8%;
  font-size: 40px;
  margin-top: 16px;
  margin-bottom: 20px;
  letter-spacing: 8px;
  font-weight: bold;
  background: -webkit-linear-gradient(to right,  rgba(98, 205, 196), rgba(33, 182, 234)); /* Chrome, Safari */
  background: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234)); /* 标准语法 */
  -webkit-background-clip: text; /* Chrome, Safari */
  -webkit-text-fill-color: transparent; /* Chrome, Safari */
  color: transparent; /* 兼容不支持background-clip的浏览器 */
}
#oneStop-con {
  padding-left: 8%;
  font-weight: bold;
}
#contactUs {
  font-size: 34px;
  font-weight: bold;
  color: white;
}
#consulting{
  margin-top: 2%;
  font-weight: bold;
  font-size: 34px;
  color: white;
  display: flex;
  justify-content: space-around; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 水平居中文本 */
  border-radius: 10px;
  width: 12%;
  height: 50%;
  background: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234));
}
#middle {
  width: 44%;
}
.cooperativeImg {
  margin-top: 40px;
  justify-content: space-around;
  display: flex;
  height: 30%;
}
</style>
