<template>
  <div id="tool">
    <div id="blank"></div>
    <div id="title-cn">
      新闻咨询
    </div>
    <div id="content-newWork">
      <div v-for="(item, index) in this.newWork" :key="index" >
        <newWork :content="item" />
      </div>
    </div>
<!--    <div id="title-en">-->
<!--      QUERY TOOL-->
<!--    </div>-->
<!--    <div class="line"></div>-->
<!--    <div id="iconAndTitle">-->
<!--      <div class="icon-all">-->
<!--        <div v-for="(item, index) in icons" :key="index" class="icon-mar">-->
<!--          <icon :imgPath="item.img" :title="item.title"/>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="icon-all">-->
<!--        <div v-for="(item, index) in icons2" :key="index" class="icon-mar">-->
<!--          <icon :imgPath="item.img" :title="item.title"/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import icon from '../../components/home/icon.vue'
import Service from "@/components/home/Service";
import newWork from '../../views/newWork/newWork.vue';

export default {
  components: {
    icon,
    newWork
  },
  name: 'Advantage.vue',
  data() {
    return {
      newWork : [
        {
          img: require('@/assets/newWork/1.jpg'),
          content: "一周猛料|第五届西部数博会；首列巴库—西安货运列车开行；黑五网一......",
          hrefPath: "https://mp.weixin.qq.com/s/ybx5aZTPK2GvyeQH0VhBrQ"
        },
        {
          img: require('@/assets/newWork/2.jpg'),
          content: "商务部等9部门：支持商贸流通、跨境电商、跨境物流等企业高质量推进海外仓......",
          hrefPath: "https://mp.weixin.qq.com/s/W6sZ-IrwJ04XEtpWkkNGgQ"
        },
        {
          img: require('@/assets/newWork/3.jpg'),
          content: "政策图解|《商务部关于印发促进外贸稳定增长若干政策措施的通知》",
          hrefPath: "https://mp.weixin.qq.com/s/ryVw50ExwCj7RqhyPGEvpw"
        }
      ],
      icons: [
        {
          img: require('@/assets/homepageimges/icon/1.png'),
          title: "汇率查询"
        },
        {
          img: require('@/assets/homepageimges/icon/2.png'),
          title: "组织架构代码"
        },
        {
          img: require('@/assets/homepageimges/icon/3.png'),
          title: "HS编码查询"
        },
        {
          img: require('@/assets/homepageimges/icon/4.png'),
          title: "税率查询"
        },
        {
          img: require('@/assets/homepageimges/icon/5.png'),
          title: "组织架构代码"
        }
      ],
      icons2: [{
        img: require('@/assets/homepageimges/icon/6.png'),
        title: "货物跟踪"
      },
        {
          img: require('@/assets/homepageimges/icon/7.png'),
          title: "申报要素"
        },
        {
          img: require('@/assets/homepageimges/icon/8.png'),
          title: "进出口税费查询"
        },
        {
          img: require('@/assets/homepageimges/icon/9.png'),
          title: "外贸公司/代表处信用查询"
        },
        {
          img: require('@/assets/homepageimges/icon/10.png'),
          title: "各国汇率查询"
        }
      ]
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
#tool {
  text-align: center; /* 水平居中文本 */
  width: 100%;
  height: 520px;
  background: #F0FDFF;
}

.line {
  width: 4%;
  height: 3px;
  margin-left: 48%;
  margin-top: 10px;
  background-color: rgba(33, 182, 234);
}
#iconAndTitle {
  width: 80%;
  margin: auto;
}
.icon-all {
  margin-top: 40px;
  display: flex;
  justify-content: space-around; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 水平居中文本 */
}
#title-cn {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}
#title-en {
  margin-top: 1%;
  font-size: 12px;
}
#blank {
  height: 50px;
}
#content-newWork {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
