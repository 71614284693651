<template>
  <div class="newWork-item">
      <img :src=this.content.img />
      <el-link :underline="false" :href=this.content.hrefPath target="_blank" class="newWork-font">{{this.content.content}}</el-link>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      items: []
    }
  },
}
</script>
<style lang="less" scoped>
#content-newWork {
  display: flex;
  align-items: center;
  justify-content: center;
}
.newWork-item {
  width: 400px;
  margin: 0 100px;
  img {
    width: 100%;
  }
  .el-link {
    margin: 10px;
    color: black;
    font-size: 20px;
  }
}
</style>