<template>
  <div class="process">
    <div class="title">
      <p id="title-h">
        在线申请，流程清晰
      </p>
      <p id="title-c">
        首次使用兴贸通的客户，如需了解{{titleContext}}，请咨询您的客户经理
      </p>
    </div>
    <div class="arrows">
      <div v-for="(item, index) in arrows" :key="index" >
        <arrow :serial="item.serial" :describe="item.describe"/>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from "@/components/common/arrow";

export default {
  components: {
    arrow
  },
  props: {
    arrows: {
      type: Array,
      required: true
    },
    titleContext: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      items: []
    }
  },
}
</script>

<style lang="less" scoped>

.process {
  height: 500px;
  background-image: url('/src/assets/platformServices/4.jpg');
  display: grid;
  place-items: center; /* 水平垂直居中 */
}
.title {
  text-align: center;
  height: 100px; /* 容器的高度 */
}
.arrows {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#title-h {
  font-size: 30px;
  font-weight: bold;
  background: -webkit-linear-gradient(to right,  rgba(98, 205, 196), rgba(33, 182, 234)); /* Chrome, Safari */
  background: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234)); /* 标准语法 */
  -webkit-background-clip: text; /* Chrome, Safari */
  -webkit-text-fill-color: transparent; /* Chrome, Safari */
  color: transparent; /* 兼容不支持background-clip的浏览器 */
  line-height: 1.5;
}
#title-c {
  font-family: Arial, Helvetica, sans-serif;
}
</style>


