import { render, staticRenderFns } from "./contactUs.vue?vue&type=template&id=40de5c2a&scoped=true"
import script from "./contactUs.vue?vue&type=script&lang=js"
export * from "./contactUs.vue?vue&type=script&lang=js"
import style0 from "./contactUs.vue?vue&type=style&index=0&id=40de5c2a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40de5c2a",
  null
  
)

export default component.exports