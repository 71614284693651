<template>
  <div class="floating-box">
    <!-- 这里放置悬浮框的内容 -->
    <img class="showImage" :src="items.image">
    <div>联系电话：</div>
    <div>029-85230860</div>
  </div>
</template>

<script>
export default {
  // 组件的逻辑代码
  data() {
    return {
      items:
        {
          image: require('@/assets/contactUs/微信二维码.png'),
          content: '微信扫码添加',
        }
    }
  },
};
</script>

<style scoped>
.floating-box {
  z-index: 9999;
  /* 悬浮框的样式，例如背景色、边框、位置等 */
  position: fixed;
  top: 60%;
  right: 0;
  color: black;
}
.showImage {
  width: 55%;
  height: 55%;
}
</style>